import React from "react";
import { use100vh } from "react-div-100vh";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";

import styled from "styled-components";

// // SEO
import { PageSEO } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 51px 0 0 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & .page-container {
    max-width: 480px;
    margin: 0 auto;
  }

  & .placeholder {
    height: 50px;
  }

  & h1,
  & p {
    text-align: center;

    & a {
    }
  }

  @media (max-width: 960px) {
    padding: 30px;

    & h1,
    & p {
      font-size: 12px;
      line-height: 17px;
    }
  }

  @media (max-width: 500px) {
    & h1,
    & p {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

const NotFoundPage = ({ location }) => {
  // Window Height
  const height = use100vh();

  if (height === null) return null;

  return (
    <>
      <PageSEO seoTitle={`404 Page Not Found`} seoText={null} seoImage={null} />

      <Page height={height}>
        <div className="page-container">
          <div className="text-container">
            <h1>404 Page Not Found</h1>
            <p className="news-gothic">
              The page you requested does not exist.
            </p>
          </div>
        </div>
      </Page>
    </>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
